<template>
  <div>
    <div class="dashhead">
      <div class="dashhead-titles">
        <h6 class="dashhead-subtitle">{{subTitle}}</h6>
        <h3 class="dashhead-title">{{title}}</h3>
      </div>
    </div>

    <hr class="my-3">

    <sgv-form
      :method.sync="method"
      :options="options">
      <DetailForm
        :procedureType="procedureType"
        :templateType="templateType"
        :method="method"
        :formData="formData"
        :v="$v">
      </DetailForm>
    </sgv-form>

    <template v-if="procedureControlId > 0">
      <HrDivider
        :options="tabs"
        v-model="selectedTab"
        select="value">
      </HrDivider>

      <DetailOrder
        v-if="selectedTab === 'order'"
        :procedureType="procedureType"
        :templateType="templateType"
        :parentId="procedureControlId"
        :children="formData.children"
        :isEdit="method === 'edit'"
        @updated="dataDetail">
      </DetailOrder>

      <DetailMaster
        v-if="selectedTab === 'master' && formData.master"
        :procedureType="procedureType"
        :templateType="templateType"
        :parentId="procedureControlId"
        :formData="formData"
        :isEdit="method === 'edit'"
        @updated="dataDetail">
      </DetailMaster>
    </template>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import DetailForm from './DetailForm.vue'
import DetailOrder from './DetailOrder.vue'
import DetailMaster from './DetailMaster.vue'
import {
  DETAIL_PROCEDURE_CONTROL,
  CREATE_PROCEDURE_CONTROL,
  UPDATE_PROCEDURE_CONTROL,
  DESTROY_PROCEDURE_CONTROL
} from './graph'

export default {
  metaInfo () {
    return {
      title: `${this.formData.name}` || `${this.title}`
    }
  },
  props: {
    procedureType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    procedureControlId: {
      type: Number,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    subTitle: {
      type: String,
      required: true
    },
    group: {
      type: String,
      required: true
    },
  },
  data () {
    return {
      formData: {
        code: '',
        name: '',
        isActive: true,
        children: [],
        categories: [],
        masterId: null,
        master: null
      },
      method: 'info',
      listView: `${this.templateType}${this.$form.capitalize(this.procedureType)}List`,
      isLoaded: false,
      selectedTab: 'master',
    }
  },
  validations: {
    formData: {
      code: { required },
      name: { required },
    }
  },
  computed: {
    tabs () {
      const arr = [
        {text: 'รายการ', value: 'order'},
      ]

      if (this.formData.masterId) {
        arr.push({text: 'master', value: 'master'})
      }

      return arr
    },
    options () {
      return [
        {
          text: 'เพิ่ม',
          variant: 'success',
          method: 'add',
          func: this.createData,
          disabled: true
        },
        {
          text: 'แก้ไข',
          variant: 'warning',
          method: 'edit',
          func: this.updateData,
          disabled: this.method === 'add' || !this.$auth.hasRole(`procedure:${this.procedureType}:control:add`)
        },
        {
          text: 'ลบ',
          variant: 'danger',
          method: 'delete',
          func: this.destroyData,
          disabled: this.method === 'add' || !this.$auth.hasRole(`procedure:${this.procedureType}:control:add`)
        },
        {
          text: 'กลับ',
          variant: 'primary',
          func: this.closeForm,
          header: true,
          align: 'right'
        },
      ]
    }
  },
  methods: {
    dataDetail () {
      return this.$apollo.query({
        query: DETAIL_PROCEDURE_CONTROL(this.templateType),
        variables: {
          procedureType: this.procedureType,
          procedureControlId: this.procedureControlId
        },
        fetchPolicy: 'network-only'
      })
      .then(res => {
        this.setFormData(res.data.procedureControl)
        return res.data.procedureControl
      })
      .catch(this.$toasted.global.error)
      .finally(() => {this.isLoaded = true})
    },
    setFormData (v) {
      Object.keys(this.formData).forEach(key => {
        if (key === 'categories') {
          this.formData[key] = v[key].map(x => x.id)
          return
        }
        this.formData[key] = v[key]
      })
    },
    serializeInput (v) {
      const input = {...v}
      delete input.children
      delete input.master
      return input
    },
    createData () {
      this.$v.formData.$touch()
      if (this.$v.formData.$invalid) return

      const input = this.serializeInput(this.formData)
      this.$apollo.mutate({
        mutation: CREATE_PROCEDURE_CONTROL(this.templateType),
        variables: {
          procedureType: this.procedureType,
          input
        }
      })
      .then(res => {
        this.method = 'info'
        this.$toasted.global.success("เพิ่มสำเร็จ")
        this.$router.push({
          name: this.$route.name,
          params: {
            ...this.$route.params,
            procedureControlId: res.data.createProcedureControl.id
          },
          query: {...this.$route.query, redirect: this.listView}
        })
      })
      .catch(this.$toasted.global.error)
    },
    updateData () {
      this.$v.formData.$touch()
      if (this.$v.formData.$invalid) return

      const input = this.serializeInput(this.formData)
      this.$apollo.mutate({
        mutation: UPDATE_PROCEDURE_CONTROL(this.templateType),
        variables: {
          procedureType: this.procedureType,
          procedureControlId: this.procedureControlId,
          input
        }
      })
      .then(res => {
        this.method = 'info'
        this.setFormData(res.data.updateProcedureControl)
        this.$toasted.global.success("แก้ไขสำเร็จ")
      })
      .catch(this.$toasted.global.error)
    },
    destroyData () {
      this.$apollo.mutate({
        mutation: DESTROY_PROCEDURE_CONTROL(this.templateType),
        variables: {
          procedureType: this.procedureType,
          procedureControlId: this.procedureControlId
        }
      })
      .then(() => {
        this.$toasted.global.success("ลบสำเร็จ")
        this.closeForm()
      })
      .catch(this.$toasted.global.error)
    },
    closeForm () {
      if (this.$route.query.redirect) {
        if (this.$route.query.redirect === this.$route.name) {
          this.$router.push({name: this.listView})
        } else {
          this.$router.push({name: this.$route.query.redirect})
        }
      } else {
        this.$router.push({name: this.listView})
      }
    },
  },
  created () {
    if (this.procedureControlId === 0) {
      this.method = 'add'
      this.isLoaded = true
    } else {
      this.dataDetail()
    }
    this.$store.commit('path/setCurrent', {to: this.$route, group: this.group})
  },
  components: {
    DetailForm,
    DetailOrder,
    DetailMaster
  }
}
</script>

<style lang="css" scoped>
</style>
